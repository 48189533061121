import "core-js/modules/es.array.push.js";
import store from "@/state/store.js";
import RecentSMSChart from "./campaignDetailsReporting.vue";
import moment from "moment";
import downloadcsv from "../../../helpers/downloadcsv";
import loginType from "../../../helpers/loginType";
import _ from "lodash";
export default {
  name: "campaign-details",
  components: {
    RecentSMSChart
  },
  data() {
    return {
      campaignDetails: [],
      campaignSeries: [],
      loading: false,
      exportLoading: false,
      page: 1,
      pageSize: 50,
      total: 0,
      filterOn: false,
      statusSelectedIndex: "",
      campaignLabels: [this.$t("dashboard.campaign_history.button_report.delivered"), this.$t("dashboard.view_campaign.accepted_lower"), this.$t('dashboard.campaign_history.button_report.submitted'), this.$t('dashboard.view_campaign.not_lower')],
      buttonDisabled: true,
      tableLoading: false,
      searchTableData: "",
      processingFlag: false
    };
  },
  methods: {
    redirect() {
      if (loginType() === "sms") {
        this.$router.push("/sms/campaign/history").catch(err => {
          if (err.name !== "NavigationDuplicated") throw err;
        });
      } else {
        this.$router.push("/voice/campaign/history").catch(err => {
          if (err.name !== "NavigationDuplicated") throw err;
        });
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      loginType() === "sms" ? this.fetchSpecificPage(val) : this.fetchSpecificVoice(val);
    },
    fetchSpecificPage(page) {
      this.tableLoading = true;
      store.dispatch("reporting/fetchSMSHistoryDetails", {
        id: this.$route.params.id,
        page: page
      }).then(data => {
        this.campaignDetails = data.data;
        this.total = data.total;
        // this.findStatus(data.data)
      }).finally(() => this.tableLoading = false);
    },
    fetchSpecificVoice(page) {
      this.tableLoading = true;
      store.dispatch("reporting/fetchVoiceHistory", {
        id: this.$route.params.id,
        page: page
      }).then(data => {
        this.campaignDetails = data.data;
        this.total = data.total;
        // this.findStatus(data.data)
      }).finally(() => this.tableLoading = false);
    },
    dataPointSelection(chartContext) {
      this.filterOn = true;
      this.statusSelectedIndex = this.campaignLabels[chartContext.dataPointIndex];
    },
    exportReport() {
      this.exportLoading = true;
      if (loginType() === "sms") {
        store.dispatch("reporting/eportCampaignDetails", this.$route.params.id).then(data => {
          downloadcsv(data, `Campaign Details_${this.$route.params.id}_Individual_SMS`);
        }).finally(() => this.exportLoading = false);
      } else {
        store.dispatch("reporting/exportCampaignHistoryVoice", this.$route.params.id).then(data => {
          downloadcsv(data, `Campaign Details_${this.$route.params.id}_Individual_Voice`);
        }).finally(() => this.exportLoading = false);
      }
    },
    findStatus(data) {
      const statusObject = {};
      data.forEach(obj => {
        if (obj.status == "FAILED") {
          obj.status = "ACCEPTED";
        }
        statusObject[obj.status] = obj.total;
      });
      this.campaignSeries = Object.values(statusObject);
      this.campaignLabels = Object.keys(statusObject);
    },
    findVoiceStatus(data) {
      const statusObjet = {};
      data.forEach(obj => {
        if (obj.dial_status == "not_dialed_yet") {
          obj.dial_status = "PENDING";
        }
        statusObjet[obj.dial_status] = obj.total;
      });
      this.campaignSeries = Object.values(statusObjet);
      this.campaignLabels = Object.keys(statusObjet);
    },
    refreshPageDisable() {
      this.$router.go(0);
      // disable button for 30 seconds with set timeout
    },
    checkRefund(scope) {
      if (scope.dial_status == "NO ANSWER") {
        if (scope.refund == "1") {
          return "REFUNDED";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    searchUser: _.debounce(function () {
      if (this.searchTableData === "") {
        return this.prepareCampaignDetails();
      }
      if (this.searchTableData !== "") {
        this.tableLoading = true;
        let payload = {
          campaign_id: this.$route.params.id,
          phone: this.searchTableData,
          service_type: loginType() == "sms" ? "sms" : "voice"
        };
        store.dispatch("reporting/searchCampaignHistory", payload).then(data => {
          this.campaignDetails = data.data;
          this.total = data.data.length;
        }).catch(() => {
          this.$message({
            type: "error",
            message: "There was an issue searching for this contact"
          });
        }).finally(() => {
          this.tableLoading = false;
        });
      }
      // Hit your endpoint here using this.searchTerm as the search query.
    }, 700),
    prepareCampaignDetails() {
      this.tableLoading = true;
      if (loginType() == "sms") {
        store.dispatch("reporting/fetchSMSHistoryDetails", {
          id: this.$route.params.id,
          page: 1
        }).then(data => {
          this.findStatus(data.message_status);
          this.campaignDetails = data.data;
          this.total = data.total;
        }).finally(() => {
          this.tableLoading = false;
        });
      } else {
        store.dispatch("reporting/fetchVoiceHistory", {
          id: this.$route.params.id,
          page: 1
        }).then(data => {
          this.campaignDetails = data.data;
          this.findVoiceStatus(data.voice_status);
          this.total = data.total;
        }).finally(() => this.tableLoading = false);
      }
    }
  },
  computed: {
    displayData() {
      // this.total = this.searching.length;
      return this.campaignDetails;
    },
    logintype() {
      return loginType();
    },
    isProcessing() {
      return this.campaignDetails.length == 0 && this.processingFlag && this.$t('dashboard.view_campaign.currently_processing') || "No data found";
    }
  },
  beforeMount() {
    this.loading = true;
    if (loginType() == "sms") {
      store.dispatch("reporting/fetchSMSHistoryDetails", {
        id: this.$route.params.id,
        page: 1
      }).then(data => {
        this.findStatus(data.message_status);
        this.campaignDetails = data.data;
        this.total = data.total;
        if (this.campaignDetails.length == 0) {
          this.processingFlag = true;
        }
      }).finally(() => {
        this.loading = false;
        if (localStorage.getItem("askedForReview") == "true") {
          store.dispatch("modal/showFeedbackModal", true);
        }
        if (sessionStorage.getItem("hasFilledCategory") == null) {
          store.dispatch("modal/showCategoriesModal", true);
        }
      });
    } else {
      store.dispatch("reporting/fetchVoiceHistory", {
        id: this.$route.params.id,
        page: 1
      }).then(data => {
        this.campaignDetails = data.data;
        this.findVoiceStatus(data.voice_status);
        this.total = data.total;
      }).finally(() => this.loading = false);
    }
  },
  created() {
    setTimeout(() => {
      this.buttonDisabled = false;
    }, 30000);
  }
};