var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "section"
  }, [_c('div', {
    ref: "chart",
    staticClass: "contains-chart",
    attrs: {
      "id": "chart"
    }
  }, [_c('apexcharts', {
    attrs: {
      "type": "donut",
      "width": "100%",
      "height": "90%",
      "options": _vm.chartOptions,
      "series": _vm.chartOptions.series
    }
  })], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };